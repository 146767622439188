/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Link from '../Link';
import { Button } from '..';
import Widget from './Widget';

export default props => {
  const translate = getTranslate(useSelector(state => state.localize));
  const firstName = useSelector(state => state.session && state.session.user && state.session.user.firstname) || '';

  return (
    <Widget
      title={translate('tracking.heyYou', { firstName })}
      bg="blueLighter"
      color="text"
      titleColor="primary"
      {...props}
    >
      <Box>{translate('tracking.goToOwnParcels')}</Box>
      <Box sx={{ mt: 3 }}>
        <Button as={Link} to="/my-pages/incoming-parcels">
          {translate('account.myParcels.title')}
        </Button>
      </Box>
    </Widget>
  );
};

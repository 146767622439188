/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Link from '../../components/Link';
import { Button } from '..';
import Widget from './Widget';

export default props => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Widget title={translate('registerWidget.title')} bg="blueLighter" color="text" titleColor="primary" {...props}>
      <Box>{translate('registerWidget.description')}</Box>
      <Box sx={{ mt: 3 }}>
        <Button as={Link} to="/rekisteroidy">
          {translate('registerWidget.register')}
        </Button>
      </Box>
    </Widget>
  );
};

/** @jsx jsx */
import { jsx, Box, Styled, Container, Button } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { ColumnLayout } from '../components';
import OwnParcelsWidget from '../components/widgets/OwnParcelsWidget';
import RegisterWidget from '../components/widgets/RegisterWidget';
import Widget from '../components/widgets/Widget';
import Link from '../components/Link';
import 'url-search-params-polyfill'; // for IE
import * as analytics from '../utils/analytics';
import { graphql, useStaticQuery } from 'gatsby';
import AlertNotification from '../components/AlertNotification';
import { TrackByQuery } from '../components/TrackShipment';

function Tracking({ pageContext, parcelNumber, location }) {
  analytics.usePageCategory('paketit');
  const paths = (pageContext && pageContext.paths) || {};
  const locale = (pageContext && pageContext.locale) || 'en';
  const translate = getTranslate(useSelector(state => state.localize));
  const isLoggedIn = useSelector(state => !!state.session.user);

  const notificationData = useStaticQuery(notificationQuery);
  const trackingNote = notificationData?.allContentfulComponent?.edges?.find(e =>
    e?.node?.node_locale?.startsWith(locale)
  );

  return (
    <ColumnLayout
      title={translate('tracking.search.title')}
      paths={paths}
      locale={locale}
      darkMode={false}
      showAppBanner="paketit"
      sidebar={
        <Box sx={{ mt: 5, mx: [-3, 0] }}>
          {isLoggedIn ? <OwnParcelsWidget sidebar /> : <RegisterWidget sidebar />}
          <Widget title={translate('paketitWidget.title')} bg="pink" color="text" titleColor="primary" sx={{ mt: 3 }}>
            <Box>{translate('paketitWidget.description')}</Box>
            <Box sx={{ mt: 3 }}>
              <Button as={Link} to={translate('paketitWidget.link')}>
                {translate('paketitWidget.button')}
              </Button>
            </Box>
          </Widget>
        </Box>
      }
    >
      <Container sx={{ maxWidth: 1024, py: [2, 3, null, 4] }}>
        <Styled.h1>{translate('tracking.search.title')}</Styled.h1>
        {trackingNote && trackingNote?.node?.body && <AlertNotification body={trackingNote.node.body} />}
        <TrackByQuery parcelNumber={parcelNumber} pageContext={pageContext} location={location} />
      </Container>
    </ColumnLayout>
  );
}

export default Tracking;

export const notificationQuery = graphql`
  query {
    allContentfulComponent(filter: { slug: { eq: "seuraa-pakettia" }, template: { eq: "Notification" } }) {
      edges {
        node {
          node_locale
          ...componentFields
        }
      }
    }
  }
`;
